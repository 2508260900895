import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
	{
		path: "/",
		name: "home",
		component: HomeView,
	},
	{
		path: "/courses/:id",
		name: "curso",
		props: true,
		component: () => import("../views/AboutView.vue"),
	},
	{
		path: "/edit-course/:id",
		name: "Editar Curso",
		props: true,
		component: () => import("../views/EditCourse.vue"),
		beforeEnter: (to, from, next) => {
			const userId = localStorage.getItem("user");
			if ((userId && userId === "1") || userId === 1) {
				next();
			} else {
				next({ name: "home" });
			}
		},
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
