<template>
	<main v-if="!isLogin" class="w-full h-dvh flex flex-col items-center justify-center">
		<div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div class="sm:mx-auto sm:w-full sm:max-w-md">
				<div
				class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 flex flex-col gap-4 min-w-96"
				>
					<img
						class="mx-auto h-20 w-auto"
						src="@/assets/logo.svg"
						alt="Workflow"
					/>
					<div class="sm:mx-auto sm:w-full sm:max-w-md">
						<h2 class="text-center text-3xl font-bold tracking-tight">
							<span class="text-blue-900"> Kreisky Academy</span>
						</h2>
					</div>
					<form class="space-y-4">
						<div>
							<label
								for="email"
								class="block text-sm font-medium text-gray-700"
							>
								Correo
							</label>
							<div class="mt-1">
								<input
								v-model="loginForm.email"
									id="email"
									name="email"
									type="email"
									autocomplete="email"
									required=""
									class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
								/>
							</div>
						</div>
						<div>
							<label
								for="password"
								class="block text-sm font-medium text-gray-700"
							>
								Contraseña
							</label>
							<div class="mt-1">
								<input
								v-model="loginForm.password"
									id="password"
									name="password"
									type="password"
									autocomplete="current-password"
									required=""
									class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
								/>
							</div>
						</div>
						<div>
							<button
								@click.prevent="login"
								type="submit"
								class="transition-all border rounded-md shadow-sm py-2 px-4 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 border-transparent bg-blue-900 text-white hover:bg-blue-700 focus:ring-blue-500 w-full"
							>
								Iniciar Sesión
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</main>
	<router-view v-else />
</template>

<script>
export default {
	name: "App",
	components: {},
	props: {},
	data() {
		return {
			isLogin: false,
			currentUser: {},
			loginForm: {
				email: "",
				password: "",
			},
			users: [
				{
					id: 1,
					email: "kreisky@academy.com",
					password: "123456",
					names: "Kreisky Academy",
				},
				{
					id: 2,
					email: "jhongt796@gmail.com",
					password: "123456",
					names: "Juan Florián",
				},
			]
		};
	},
	computed: {},
	watch: {},
	methods: {
		login() {
			const user = this.users.find(
				(user) =>
					user.email === this.loginForm.email &&
					user.password === this.loginForm.password
			);
			console.log({ user, loginForm: this.loginForm });
			if (user) {
				this.isLogin = true;
				this.currentUser = user;

				// Set in local storage id of user
				localStorage.setItem("user", user.id);
			} else {
				alert("Usuario o contraseña incorrectos");
			}
		},
	},
	beforeCreate() {},
	created() {},
	beforeMount() {},
	mounted() {
		const user = localStorage.getItem("user");
		if (user) {
			this.isLogin = true;
			this.currentUser = this.users.find((u) => u.id == user);
		}
	},
	beforeUpdate() {},
	updated() {},
	beforeUnmount() {},
	unmounted() {},
};
</script>

<style scoped></style>
