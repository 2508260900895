// dbController.js

import axios from "axios";

axios.defaults.baseURL =
	"https://wm45oee5jj.execute-api.us-east-1.amazonaws.com/production/api/v1";

/**
 * @typedef {Object.<string, string | number | boolean>} Vars
 * Las variables que serán usadas en el comando, con claves como nombres de variables y valores que pueden ser de tipo string, number o boolean.
 */

/**
 * @typedef {Object} ExecuteResponse
 * @property {any} result - El resultado de la ejecución del comando en el servidor.
 */

/**
 * Ejecuta un comando en el endpoint /db-dev.
 *
 * @param {string} command - El comando en formato string con variables, por ejemplo, `db.collection("$collection").insertOne({ name: $name, role: $role })`.
 * @param {Vars} vars - Un objeto con las variables a ser sustituidas en el comando.
 * @returns {Promise<ExecuteResponse>} Una promesa que resuelve con el resultado de la ejecución del comando.
 */
export async function exec(command, vars) {
	try {
		const response = await axios.post("/db-dev", { command, vars });
		return response.data;
	} catch (error) {
		console.error(
			"Error executing command:",
			error.response?.data || error.message
		);
		throw new Error(error.response?.data?.error || "Failed to execute command");
	}
}
